import { useAtomValue } from "jotai";
import { selectedDateAtom } from "./store";
import CharterSlots from "./CharterSlots";
import { textStyles } from "../../styles/typography";
import { ccn } from "../../styles/utils";
import { formatDate } from "../../utils/date";

type Props = {
  handleNextStep: () => void;
};

export default function AvailableSlots({ handleNextStep }: Props) {
  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <div className={ccn(textStyles.sub1, "text-blue-dark60")}>
          Choose Your Time
        </div>
        <BoatSlotInfo />
      </div>
      <CharterSlots handleNextStep={handleNextStep} />
      <div className={ccn("mt-6 text-blue-dark60", textStyles.label)}>
        *You will see the final price at the next step
      </div>
    </div>
  );
}

const BoatSlotInfo = () => {
  const selectedDay = useAtomValue(selectedDateAtom);
  // const selectedSlot = useAtomValue(selectedSlotAtom);

  return (
    <div
      className={ccn(
        textStyles.h2,
        "flex flex-col gap-3 md:gap-0 md:flex-row justify-between"
      )}
    >
      {/* <div className="text-blue-dark">{listing?.title}</div> */}
      <div className="text-blue-light">
        {formatDate(selectedDay)}
        {/* {selectedSlot && (
          <>
            ; {formatSlotTime(selectedSlot?.interval.start)} —{" "}
            {formatSlotTime(selectedSlot?.interval.end)}
          </>
        )} */}
      </div>
    </div>
  );
};
