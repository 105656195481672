import { SVGProps } from "react";
import { ccn } from "../../styles/utils";
import { textStyles } from "../../styles/typography";
import { useListingQuery } from "../../api/hooks";

export default function Header({
  showBoatInfo = true,
}: {
  showBoatInfo?: boolean;
}) {
  return (
    <div className="flex items-center justify-between md:py-5 md:px-10 p-4 border-b border-grey-light md:gap-10 gap-4">
      {showBoatInfo && <BoatSlotInfo />}
      <div
        className="p-2 cursor-pointer"
        onClick={() => {
          window.parent.postMessage(
            {
              close: true,
            },
            "*"
          );
        }}
      >
        <IconClose />
      </div>
    </div>
  );
}

const IconClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="#2A3D8C"
      d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41Z"
    />
  </svg>
);

const BoatSlotInfo = () => {
  // const selectedDay = useAtomValue(selectedDateAtom);
  // const selectedSlot = useAtomValue(selectedSlotAtom);

  const { data: listing } = useListingQuery();

  return (
    <div
      className={ccn(
        textStyles.h2,
        "flex flex-col gap-3 md:gap-0 md:flex-row justify-between"
      )}
    >
      <div className="text-blue-dark">{listing?.title}</div>
      <div className="text-blue-light">
        {/* {formatDate(selectedDay)}
        {selectedSlot && (
          <>
            ; {formatSlotTime(selectedSlot?.interval.start)} —{" "}
            {formatSlotTime(selectedSlot?.interval.end)}
          </>
        )} */}
      </div>
    </div>
  );
};
