import { SVGProps, useEffect } from "react";
import { textStyles } from "../../styles/typography";
import { cn } from "../../styles/utils";
import { useListingQuery } from "../../api/hooks";
import { useAtomValue, useSetAtom } from "jotai";
import {
  orderDataAtom,
  paymentResponseAtom,
  orderStatusAtom,
  passengersAtom,
  selectedPackageAtom,
  customerDetailsAtom,
} from "./store";
import { PaymentStatus, Query } from "@repo/rq-api-public";

export default function PaymentPending() {
  const orderData = useAtomValue(orderDataAtom);
  const passengers = useAtomValue(passengersAtom);
  const selectedPackage = useAtomValue(selectedPackageAtom);
  const customerDetails = useAtomValue(customerDetailsAtom);

  const setOrderStatus = useSetAtom(orderStatusAtom);
  const setPaymentResponse = useSetAtom(paymentResponseAtom);

  const { data: listing } = useListingQuery();
  const {
    data: paymentInfo,
    isPending,
    isRefetching,
    refetch,
  } = Query.usePaymentsStatusGETQuery({
    guid: orderData?.paymentGuid,
  });

  const { data: calculation } = Query.usePricingsCalculateGETQuery({
    passengers,
    pricingGuid: selectedPackage?.pricingGuid,
  });

  useEffect(() => {
    if (!paymentInfo || isRefetching || isPending) {
      return;
    }

    if (
      paymentInfo.status === PaymentStatus.Intent ||
      paymentInfo.status === PaymentStatus.Processing
    ) {
      setTimeout(() => {
        refetch();
      }, 1000);
    }

    if (paymentInfo.status === PaymentStatus.Complete) {
      window.parent.postMessage(
        {
          trackEvent: "payment",
          orderId: orderData?.bookingGuid,
          value: calculation?.totalPrice,
          boatTitle: listing?.title,
          buyerEmail: customerDetails?.email,
          currencySymbol: "$",
        },
        "*"
      );
      setPaymentResponse(paymentInfo);
      setOrderStatus("SUCCESS");
    }

    if (paymentInfo.status === PaymentStatus.Failed) {
      setOrderStatus("PAYMENT_FAILED");
    }
  }, [isRefetching, isPending]);

  return (
    <div className="flex flex-col items-center p-10">
      <IconLoading className="mb-4" />
      <div className={cn(textStyles.h2, "text-blue-dark mb-3")}>
        Your payment is pending...
      </div>
      <div className={cn(textStyles.body1, "text-blue-dark mb-10")}>
        This may take some time, please wait. Once completed you will be
        automatically redirected
      </div>
    </div>
  );
}

const IconLoading = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={120}
    height={120}
    fill="none"
    {...props}
  >
    <circle cx={60} cy={60} r={60} fill="#F3F3F3" />
    <rect width={80} height={60} x={20} y={30} fill="#fff" rx={4} />
    <path
      className="origin-center animate-spin transform-box-fill-box"
      fill="#26A9E0"
      d="M61.5 58v4a1.5 1.5 0 1 1-3 0v-4a1.5 1.5 0 1 1 3 0ZM72 68.5h-4a1.5 1.5 0 1 0 0 3h4a1.5 1.5 0 1 0 0-3Zm-5.282 6.096a1.501 1.501 0 0 0-2.451 1.638c.075.182.186.348.326.487l2.828 2.829a1.503 1.503 0 0 0 2.125-2.125l-2.829-2.829ZM60 76.5a1.5 1.5 0 0 0-1.5 1.5v4a1.5 1.5 0 1 0 3 0v-4a1.5 1.5 0 0 0-1.5-1.5Zm-6.718-1.904-2.828 2.829a1.503 1.503 0 1 0 2.125 2.125l2.828-2.829a1.503 1.503 0 0 0-2.124-2.125ZM53.5 70a1.5 1.5 0 0 0-1.5-1.5h-4a1.5 1.5 0 1 0 0 3h4a1.5 1.5 0 0 0 1.5-1.5Zm-.925-9.546a1.503 1.503 0 1 0-2.125 2.125l2.832 2.825a1.503 1.503 0 0 0 2.126-2.125l-2.833-2.825Z"
    />
    <path fill="#F3F3F3" d="M20 42h80v8H20z" />
  </svg>
);
