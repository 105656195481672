export interface Picture {
  host?: string | null;
  bucket?: string | null;
  name?: string | null;
  extension?: string | null;
}

type Resize =
  | {
      width: number;
      height: number;
    }
  | {
      size: number;
    };

// Utility for constructing responsive image URLs
export const getImageUrl = (picture: Picture, resize?: Resize) => {
  if (resize) {
    // Processed picture: {picture.Host}/{toBase64({"bucket": picture.Bucket, "key": picture.Name + picture.Extension, "edits": {"resize": {"width": 100, "height": 100}}})}
    const payload = {
      bucket: picture.bucket,
      key: `${picture.name}.${picture.extension}`,
      edits: {
        resize: {
          width: "size" in resize ? resize.size : resize.width,
          height: "size" in resize ? resize.size : resize.height,
        },
      },
    };
    return `${picture.host}/${btoa(JSON.stringify(payload))}`;
  }

  // Non processed picture: {picture.Host}/{picture.Name}.{picture.Extension}
  return `${picture.host}/${picture.name}.${picture.extension}`;
};
