import { useAtomValue, useSetAtom } from "jotai";
import { renderFromToTimeString } from "../../utils/date";
import SlotComponent from "./SlotComponent";
import {
  listingGuidAtom,
  passengersAtom,
  selectedDateAtom,
  filterPricingGuidAtom,
  isGroupPricingPackagesAtom,
  selectedPackageAtom,
} from "./store";
import SlotUnavailableMessage from "./SlotUnavailableMessage";
import { DayPackagePriceResult, Query } from "@repo/rq-api-public";
import { groupBy } from "../../utils/array";
import { keepPreviousData } from "@tanstack/react-query";
import { useDebounce } from "../hooks";

export default function CharterSlots({
  handleNextStep,
}: {
  handleNextStep: () => void;
}) {
  const selectedDay = useAtomValue(selectedDateAtom);
  const listingGuid = useAtomValue(listingGuidAtom);
  const filterPricingGuid = useAtomValue(filterPricingGuidAtom);
  const isGroupPricingPackages = useAtomValue(isGroupPricingPackagesAtom);

  const { data: calendarDayPricings } =
    Query.useListingsGuidCalendarDayPricingsGETQuery(
      {
        guid: listingGuid!,
        date: selectedDay,
      },
      {
        placeholderData: keepPreviousData,
      }
    );

  if (!calendarDayPricings) {
    return null;
  }

  const allSlotsUnavailable = calendarDayPricings?.dayPackagePrices?.every(
    (packagePricing) => !packagePricing.isAvailable
  );

  const groupedDayPackagePrices = groupBy(
    calendarDayPricings?.dayPackagePrices ?? [],
    (packagePricing) => packagePricing.pricingGuid!
  );

  return (
    <div>
      <ul className="flex flex-col gap-4">
        {isGroupPricingPackages &&
          filterPricingGuid &&
          groupedDayPackagePrices?.[filterPricingGuid]?.map(
            (packagePricing) => (
              <li key={packagePricing.guid}>
                <PartDayCharterSlot
                  packagePricing={packagePricing}
                  handleNextStep={handleNextStep}
                />
              </li>
            )
          )}

        {!isGroupPricingPackages &&
          calendarDayPricings?.dayPackagePrices?.map((packagePricing) => (
            <li key={packagePricing.guid}>
              <PartDayCharterSlot
                packagePricing={packagePricing}
                handleNextStep={handleNextStep}
              />
            </li>
          ))}
      </ul>
      {allSlotsUnavailable && <SlotUnavailableMessage />}
    </div>
  );
}

const PartDayCharterSlot = ({
  handleNextStep,
  packagePricing,
}: {
  packagePricing: DayPackagePriceResult;
  handleNextStep: () => void;
}) => {
  const passengers = useAtomValue(passengersAtom);
  const debouncedPassengers = useDebounce(passengers);
  const setSelectedPackage = useSetAtom(selectedPackageAtom);

  const { startTime, duration, pricingGuid, isAvailable } = packagePricing;

  const { data: calculation, isLoading } = Query.usePricingsCalculateGETQuery({
    passengers: debouncedPassengers,
    pricingGuid,
  });

  if (!startTime || !duration || !pricingGuid) {
    return null;
  }

  return (
    <SlotComponent
      title={packagePricing.name!}
      fromToTime={renderFromToTimeString(startTime, duration)}
      price={calculation?.totalPriceWithoutOnlineCustomerFee}
      handleBook={() => {
        setSelectedPackage(packagePricing);
        handleNextStep();
      }}
      disabled={!isAvailable}
      loading={isLoading}
    />
  );
};
